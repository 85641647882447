import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const player = {
  id: Math.random().toString(36).substring(7),
};

export default function FrontPage({ socket }) {
  const [isSearching, setIsSearching] = useState(false);
  const [players, setPlayers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    socket.on('waiting', (waitingPlayers) => {
      setPlayers(waitingPlayers);
    });

    socket.on('Matched', (datas) => {
      datas[0] = player.id;
      navigate('/fight', { state: datas });
    });

    return () => {
      socket.off('waiting');
      socket.off('Matched');
    };
  }, [socket, navigate]);

  const joinLobby = () => {
    socket.emit('playerAdd', player.id);
    setIsSearching(true);
  };

  const exitMatchMaking = () => {
    socket.emit('RemovePlayer', player.id);
    setIsSearching(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 text-white p-4">
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-5xl font-bold mb-6"
      >
        Welcome to LeetFights
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="text-xl mb-8 text-center max-w-2xl"
      >
        Enter the Coding Battle Arena, where developers clash in thrilling coding competitions!
      </motion.p>
      <AnimatePresence mode="wait">
        {isSearching ? (
          <motion.div
            key="searching"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="flex flex-col items-center"
          >
            <p className="text-2xl mb-4">Searching for opponents...</p>
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full mb-4"
            />
            <p className="mb-4">Players in queue: {players.length}</p>
            <button
              onClick={exitMatchMaking}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
            >
              Stop Matching
            </button>
          </motion.div>
        ) : (
          <motion.div
            key="buttons"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="flex space-x-4"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={joinLobby}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Start Battle
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Invite Friend
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}